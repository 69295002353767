import * as firebase from "firebase/app"
import "firebase/auth"

const config = {
  apiKey: "AIzaSyBnkYvNRMg41g8B7drOwOebtTT0u4Oqes0",
  authDomain: "sensa-front.firebaseapp.com",
  databaseURL: "https://sensa-front.firebaseio.com",
  projectId: "sensa-front",
  storageBucket: "sensa-front.appspot.com",
  messagingSenderId: "699555010496",
  appId: "1:699555010496:web:b2ffb569b0a36f0908da25",
  measurementId: "G-1QS0CGMQMV",
}
const isBrowser = () => typeof window !== "undefined"
const firebaseApp = isBrowser() ? firebase.initializeApp(config) : {}
export const firebaseAppAuth = isBrowser() ? firebaseApp.auth() : {}
export { default as useAuthChange } from "./useAuthChange"
export default firebaseApp
