import { firebaseAppAuth } from "./"
import userService from "../services/user"
import { useCallback } from "react"

export default (successCallback, errorCallback) => {
  const updateUser = (firebaseUser, value) => {
    return firebaseUser.updateProfile(value).catch(error => {
      errorCallback(error)
    })
  }
  const updateLoginProfile = (user, firebaseUser) => {
    userService
      .findUserByEmail(user.username)
      .then(response => {
        updateUser(firebaseUser, {
          displayName: `${response.firstName} ${response.lastName}`,
        }).then(() => {
          successCallback({ ...firebaseUser, sensaUser: response })
        })
      })
      .catch(error => {
        signOut()
        errorCallback(error)
      })
  }
  const signIn = user => {
    firebaseAppAuth
      .signInWithEmailAndPassword(user.username, user.password)
      .then(() => {
        const firebaseUser = firebaseAppAuth.currentUser
        if (firebaseUser) {
          updateLoginProfile(user, firebaseUser)
        }
      })
      .catch(error => {
        errorCallback(error)
      })
  }
  const signOut = useCallback(() => {
    firebaseAppAuth
      .signOut()
      .then(() => {
        successCallback()
      })
      .catch(error => {
        errorCallback(error)
      })
  }, [successCallback, errorCallback])
  return { signIn, signOut }
}
