import { useStateValue } from "../state/StateProvider"
import { useCallback } from "react"

import householdService from "../services/household"

const FETCH_HOUSEHOLD_REQUEST = "FETCH_HOUSEHOLD_REQUEST"
const UPDATE_HOUSEHOLD_REQUEST = "UPDATE_HOUSEHOLD_REQUEST"
const CREATE_HOUSEHOLD_REQUEST = "CREATE_HOUSEHOLD_REQUEST"
const FETCH_HOUSEHOLD_SUCCESS = "FETCH_HOUSEHOLD_SUCCESS"
const UPDATE_HOUSEHOLD_SUCCESS = "UPDATE_HOUSEHOLD_SUCCESS"
const CREATE_HOUSEHOLD_SUCCESS = "CREATE_HOUSEHOLD_SUCCESS"
const FETCH_HOUSEHOLD_ERROR = "FETCH_HOUSEHOLD_ERROR"
const UPDATE_HOUSEHOLD_ERROR = "FETCH_HOUSEHOLD_ERROR"
const CREATE_HOUSEHOLD_ERROR = "CREATE_HOUSEHOLD_ERROR"

export const BATH = "BATH"
export const SHOWER = "SHOWER"
export const INITIALIZED = "INITIALIZED"
export const NEW = "NEW"
const days = [
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
  "SUNDAY",
]

const initialState = { householdItem: null, error: null, loading: false }

export const addWeekDays = (washingHabits = []) => {
  const washingHabitsToMerge = []
  days.forEach(day => {
    const isDayDefined = washingHabits.some(
      washingHabit => day === washingHabit.day
    )
    if (!isDayDefined) {
      washingHabitsToMerge.push({
        day,
        devices: [
          {
            type: BATH,
            timeSlices: [],
          },
          { type: SHOWER, timeSlices: [] },
        ],
      })
    }
  })
  return washingHabitsToMerge
}

const findMissingDevices = (washingHabit, type) => {
  const device = washingHabit.devices.find(item => item.type === type)
  return device ? device.timeSlices : []
}
const addDevices = washingHabits => {
  return washingHabits.map(washingHabit => {
    if (washingHabit.devices.length <= 1) {
      return {
        ...washingHabit,
        devices: [
          {
            type: BATH,
            timeSlices: findMissingDevices(washingHabit, BATH),
          },
          {
            type: SHOWER,
            timeSlices: findMissingDevices(washingHabit, SHOWER),
          },
        ],
      }
    } else {
      return washingHabit
    }
  })
}
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CREATE_HOUSEHOLD_REQUEST:
    case FETCH_HOUSEHOLD_REQUEST:
    case UPDATE_HOUSEHOLD_REQUEST:
      return { ...state, loading: true }
    case CREATE_HOUSEHOLD_SUCCESS:
    case FETCH_HOUSEHOLD_SUCCESS:
    case UPDATE_HOUSEHOLD_SUCCESS:
      return { ...state, householdItem: payload, loading: false }
    case CREATE_HOUSEHOLD_ERROR:
    case FETCH_HOUSEHOLD_ERROR:
    case UPDATE_HOUSEHOLD_ERROR:
      return { ...state, loading: false, error: payload }
    default:
      return state
  }
}

export const useHousehold = () => {
  const [{ household }, dispatch] = useStateValue()
  const { householdItem, loading, error } = household

  const addWashingHabitsWeekDays = useCallback(
    members =>
      members.map(member => ({
        ...member,
        washingHabits: addDevices([
          ...member.washingHabits,
          ...addWeekDays(member.washingHabits),
        ]),
      })),
    []
  )

  const fetchHousehold = useCallback(
    userUUID => {
      dispatch({ type: FETCH_HOUSEHOLD_REQUEST })
      householdService
        .findByUserUUID(userUUID)
        .then(response => {
          dispatch({
            type: FETCH_HOUSEHOLD_SUCCESS,
            payload: {
              ...response,
              members: addWashingHabitsWeekDays(response.members),
            },
          })
        })
        .catch(response => {
          dispatch({ type: FETCH_HOUSEHOLD_ERROR, payload: response })
        })
    },
    [dispatch, addWashingHabitsWeekDays]
  )

  const createHousehold = household => {
    dispatch({ type: CREATE_HOUSEHOLD_REQUEST })
    return householdService
      .createHousehold({ ...household, status: INITIALIZED })
      .then(response => {
        dispatch({
          type: CREATE_HOUSEHOLD_SUCCESS,
          payload: {
            ...response,
            members: addWashingHabitsWeekDays(response.members),
          },
        })
      })
      .catch(error => {
        dispatch({ type: CREATE_HOUSEHOLD_ERROR, payload: error })
      })
  }

  const updateHouseholdMembers = householdMember => {
    const members = householdItem.members.map(member => {
      if (member.id === householdMember.id) {
        return householdMember
      }
      return member
    })
    return { ...householdItem, members }
  }

  const updateHousehold = household => {
    dispatch({ type: UPDATE_HOUSEHOLD_REQUEST })
    dispatch({ type: UPDATE_HOUSEHOLD_SUCCESS, payload: household })
  }

  const saveHousehold = household => {
    return createHousehold(household)
  }

  return {
    householdItem,
    fetchHousehold,
    createHousehold,
    updateHousehold,
    updateHouseholdMembers,
    saveHousehold,
    loading,
    error,
  }
}
