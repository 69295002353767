import { useEffect } from "react"
import { firebaseAppAuth } from "./index"

export default (addUser, removeUser) => {
  useEffect(() => {
    const unsubscribe = firebaseAppAuth.onAuthStateChanged(authUser => {
      authUser ? addUser(authUser) : removeUser()
    })
    return () => {
      unsubscribe()
    }
  }, [addUser, removeUser])
}
