import userReducer from "./userReducer"
import householdReducer from "./householdReducer"
import feedbackReducer from "./feedbackReducer"

const mainReducer = ({ user, household, feedback, firebase }, action) => {
  return {
    user: userReducer(user, action),
    household: householdReducer(household, action),
    feedback: feedbackReducer(feedback, action)
  }
}

export default mainReducer
