import BaseService from "./"

class UserService extends BaseService {
  findUserByEmail(email) {
    return new Promise((resolve, reject) => {
      this.getToken().then(token => {
        fetch(`${this.url}/user?email=${email}`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
          .then(response => response.json())
          .then(response =>
            response.error ? reject(response) : resolve(response)
          )
          .catch(error => {
            reject(error)
          })
      })
    })
  }
}

export default new UserService()
