import { firebaseAppAuth } from '../firebase'

const BASE_URL = process.env.SENSA_API_URL
const BASE_PREFIX = "/sensa-service/v1"

export default class BaseService {
  constructor() {
    this.url = `${BASE_URL}${BASE_PREFIX}`
  }

  getToken() {
    return firebaseAppAuth.currentUser.getIdToken()
  }
}
