import { useStateValue } from "../state/StateProvider"
import { useCallback } from "react"
import feedbackService from "../services/feedback"

const initialState = { userItem: null, error: null, loading: false }

const FETCH_FEEDBACK_REQUEST = "FETCH_FEEDBACK_REQUEST"
const FETCH_FEEDBACK_SUCCESS = "FETCH_FEEDBACK_SUCCESS"
const FETCH_FEEDBACK_ERROR = "FETCH_FEEDBACK_ERROR"

const FETCH_FEEDBACKS_REQUEST = "FETCH_FEEDBACKS_REQUEST"
const FETCH_FEEDBACKS_SUCCESS = "FETCH_FEEDBACKS_SUCCESS"
const FETCH_FEEDBACKS_ERROR = "FETCH_FEEDBACKS_ERROR"

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_FEEDBACKS_REQUEST:
    case FETCH_FEEDBACK_REQUEST:
      return { ...state, loading: true }
    case FETCH_FEEDBACK_SUCCESS:
      return { ...state, feedbackItem: payload, loading: false }
    case FETCH_FEEDBACKS_SUCCESS:
      return { ...state, feedbackItems: payload, loading: false }
    case FETCH_FEEDBACKS_ERROR:
    case FETCH_FEEDBACK_ERROR:
      return { ...state, loading: false, error: payload }
    default:
      return state
  }
}

export const useFeedback = () => {
  const [{ feedback }, dispatch] = useStateValue()
  const { feedbackItem, feedbackItems, loading, error } = feedback

  const fetchFeedbackRequest = useCallback(() => {
    dispatch({ type: FETCH_FEEDBACK_REQUEST })
  }, [dispatch])

  const fetchFeedbackSuccess = useCallback(
    response => {
      dispatch({
        type: FETCH_FEEDBACK_SUCCESS,
        payload: response,
      })
    },
    [dispatch]
  )

  const fetchFeedbackError = useCallback(
    error => {
      dispatch({ type: FETCH_FEEDBACK_ERROR, payload: error })
    },
    [dispatch]
  )

  const fetchFeedback = useCallback(
    userUUID => {
      fetchFeedbackRequest()
      feedbackService
        .findByUser(userUUID)
        .then(response => {
          fetchFeedbackSuccess(response)
        })
        .catch(error => {
          fetchFeedbackError(error)
        })
    },
    [fetchFeedbackRequest, fetchFeedbackError, fetchFeedbackSuccess]
  )

  const fetchFeedbacksRequest = useCallback(() => {
    dispatch({ type: FETCH_FEEDBACKS_REQUEST })
  }, [dispatch])

  const fetchFeedbacksSuccess = useCallback(
    response => {
      dispatch({
        type: FETCH_FEEDBACKS_SUCCESS,
        payload: response,
      })
    },
    [dispatch]
  )

  const fetchFeedbacksError = useCallback(
    error => {
      dispatch({ type: FETCH_FEEDBACKS_ERROR, payload: error })
    },
    [dispatch]
  )

  const fetchFeedbacks = useCallback(
    userUUID => {
      fetchFeedbacksRequest()
      feedbackService
        .findFeedbackByDay(userUUID)
        .then(response => {
          fetchFeedbacksSuccess(response)
        })
        .catch(error => {
          fetchFeedbacksError(error)
        })
    },
    [fetchFeedbacksRequest, fetchFeedbacksError, fetchFeedbacksSuccess]
  )

  return {
    feedbackItem,
    feedbackItems,
    loading,
    error,
    fetchFeedback,
    fetchFeedbacks
  }
}
