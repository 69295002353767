import BaseService from "./"

class HouseholdService extends BaseService {
  findByUserUUID(userUUID) {
    return new Promise((resolve, reject) => {
      this.getToken().then(token => {
        fetch(`${this.url}/household/${userUUID}`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
          .then(response => response.json())
          .then(response =>
            response.error ? reject(response) : resolve(response)
          )
          .catch(error => {
            reject(error)
          })
      })
    })
  }
  createHousehold(household) {
    return new Promise((resolve, reject) => {
      this.getToken().then(token => {
        fetch(`${this.url}/household`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(household),
        })
          .then(response => response.json())
          .then(response =>
            response.error ? reject(response) : resolve(response)
          )
          .catch(error => {
            reject(error)
          })
      })
    })
  }
}

export default new HouseholdService()
