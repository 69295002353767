import { useStateValue } from "../state/StateProvider"
import { useCallback } from "react"
import userService from "../services/user"
import useAuth from '../firebase/useAuth'

const initialState = { userItem: null, error: null, loading: false }

const REMOVE_USER_REQUEST = "REMOVE_USER_REQUEST"
const ADD_USER_REQUEST = "ADD_USER_REQUEST"
const ADD_USER_SUCCESS = "ADD_USER_SUCCESS"
const REMOVE_USER_ERROR = "REMOVE_USER_ERROR"
const ADD_USER_ERROR = "ADD_USER_ERROR"
const REMOVE_USER_SUCCESS = "REMOVE_USER_SUCCESS"
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case REMOVE_USER_REQUEST:
    case ADD_USER_REQUEST:
      return { ...state, loading: true }
    case ADD_USER_SUCCESS:
      return { ...state, userItem: payload, loading: false }
    case REMOVE_USER_ERROR:
    case ADD_USER_ERROR:
      return { ...state, loading: false, error: payload }
    case REMOVE_USER_SUCCESS:
      return { ...state, userItem: null, loading: false }
    default:
      return state
  }
}

export const useUser = () => {
  const [{ user }, dispatch] = useStateValue()
  const {signOut} = useAuth()
  const { userItem, loading, error } = user

  const addUserRequest = useCallback(() => {
    dispatch({ type: ADD_USER_REQUEST })
  }, [dispatch])

  const addUserSuccess = useCallback(
    user => {
      dispatch({ type: ADD_USER_SUCCESS, payload: user })
    },
    [dispatch]
  )

  const addUserError = useCallback(
    error => {
      dispatch({ type: ADD_USER_ERROR, payload: error })
    },
    [dispatch]
  )

  const addUser = useCallback(
    user => {
      if (!user.sensaUser) {
        addUserRequest()
        userService
          .findUserByEmail(user.email)
          .then(response => {
            localStorage.setItem("sensaUser", JSON.stringify(response))
            localStorage.setItem("authUser", JSON.stringify(user))
            addUserSuccess({ ...user, sensaUser: response })
          })
          .catch(error => {
            signOut();
            addUserError(error)
          })
      } else {
        addUserSuccess(user)
      }
    },
    [addUserRequest, addUserSuccess, signOut, addUserError]
  )

  const removeUserRequest = useCallback(() => {
    dispatch({ type: REMOVE_USER_REQUEST })
  }, [dispatch])

  const removeUserError = useCallback(
    error => {
      dispatch({ type: REMOVE_USER_ERROR, payload: error })
    },
    [dispatch]
  )

  const removeUserSuccess = useCallback(() => {
    localStorage.removeItem("authUser")
    localStorage.removeItem("sensaUser")
    dispatch({ type: REMOVE_USER_SUCCESS })
  }, [dispatch])

  const removeUser = useCallback(() => {
    removeUserRequest()
    try {
      removeUserSuccess()
    } catch (error) {
      removeUserError(error)
    }
  }, [removeUserRequest, removeUserSuccess, removeUserError])

  return {
    userItem,
    loading,
    error,
    addUser,
    addUserRequest,
    addUserError,
    removeUser,
    removeUserRequest,
    removeUserError,
  }
}
