/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import "./src/styles/index.scss"
import 'react-toastify/dist/ReactToastify.css';
import React from "react"
import { StateProvider } from "./src/state/StateProvider"
import { mainReducer } from "./src/reducers"
import { ToastContainer } from "react-toastify"

export const onRouteUpdate = () => {
  document.body.scrollTo(0, 0)
}

const authUser = JSON.parse(localStorage.getItem("authUser"))
const sensaUser = JSON.parse(localStorage.getItem("sensaUser"))
const initialState = {
  user: {
    userItem: { ...authUser, sensaUser },
    error: null,
    loading: false,
  },
  household: {
    householdItem: { id: null, members: [] },
    error: null,
    loading: false,
  },
  feedback: {
    feedbackItem: { benefit: null },
    feedbackItems: [],
    error: null,
    loading: false,
  },
}

export const wrapRootElement = ({ element }) => (
  <StateProvider initialState={initialState} reducer={mainReducer}>
    {element}
    <ToastContainer draggable={false} />
  </StateProvider>
)
